/* Google Fonts - Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

:root {
	--navy: #0a192f;
	--light-navy: #112240;
	--lightest-navy: #233554;
	--slate: #8892b0;
	--light-slate: #a8b2d1;
	--lightest-slate: #ccd6f6;
	--white: #e6f1ff;
	--green: #64ffda;
}

body {
	font-family: "Montserrat", sans-serif;
	background-color: var(--navy);
	color: var(--lightest-slate);
	margin: 0;
	padding: 0;
}

h1,
h2,
h3 {
	font-weight: 700;
	color: var(--white);
	margin-bottom: 10px;
}

h2 {
	text-align: center;
	margin: 40px 0;
	font-size: 28px;
}

p {
	line-height: 1.6;
	color: var(--slate);
}

a {
	color: var(--green);
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

section {
	padding: 80px 20px;
	max-width: 1000px;
	margin: 0 auto;
	background-color: var(--light-navy);
	border-radius: 8px;
}

/* Smooth scrolling */
html {
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
}
